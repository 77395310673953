import React from "react";

export interface InitializedState {
    initialized: boolean;
}

export const InitializedContext = React.createContext<InitializedState>({
    initialized: true,
});
export default InitializedContext;

export const InitializedContextWrapper: React.FC<React.PropsWithChildren> = (props) => {
    const [initialized, setInitialized] = React.useState(false);

    React.useEffect(() => {
        setInitialized(true);
    }, []);

    return <InitializedContext.Provider value={{ initialized }}>{props.children}</InitializedContext.Provider>;
};
