exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-accessibility-index-tsx": () => import("./../../../src/pages/accessibility/index.tsx" /* webpackChunkName: "component---src-pages-accessibility-index-tsx" */),
  "component---src-pages-brands-genesee-index-tsx": () => import("./../../../src/pages/brands/genesee/index.tsx" /* webpackChunkName: "component---src-pages-brands-genesee-index-tsx" */),
  "component---src-pages-brands-imperial-index-tsx": () => import("./../../../src/pages/brands/imperial/index.tsx" /* webpackChunkName: "component---src-pages-brands-imperial-index-tsx" */),
  "component---src-pages-brands-index-tsx": () => import("./../../../src/pages/brands/index.tsx" /* webpackChunkName: "component---src-pages-brands-index-tsx" */),
  "component---src-pages-brands-labatt-usa-index-tsx": () => import("./../../../src/pages/brands/labatt-usa/index.tsx" /* webpackChunkName: "component---src-pages-brands-labatt-usa-index-tsx" */),
  "component---src-pages-brands-lipton-hard-iced-tea-index-tsx": () => import("./../../../src/pages/brands/lipton-hard-iced-tea/index.tsx" /* webpackChunkName: "component---src-pages-brands-lipton-hard-iced-tea-index-tsx" */),
  "component---src-pages-brands-magic-hat-index-tsx": () => import("./../../../src/pages/brands/magic-hat/index.tsx" /* webpackChunkName: "component---src-pages-brands-magic-hat-index-tsx" */),
  "component---src-pages-brands-seagrams-escapes-index-tsx": () => import("./../../../src/pages/brands/seagrams-escapes/index.tsx" /* webpackChunkName: "component---src-pages-brands-seagrams-escapes-index-tsx" */),
  "component---src-pages-careers-index-tsx": () => import("./../../../src/pages/careers/index.tsx" /* webpackChunkName: "component---src-pages-careers-index-tsx" */),
  "component---src-pages-community-investment-grant-application-index-tsx": () => import("./../../../src/pages/community-investment/grant-application/index.tsx" /* webpackChunkName: "component---src-pages-community-investment-grant-application-index-tsx" */),
  "component---src-pages-community-investment-index-tsx": () => import("./../../../src/pages/community-investment/index.tsx" /* webpackChunkName: "component---src-pages-community-investment-index-tsx" */),
  "component---src-pages-community-investment-support-application-index-tsx": () => import("./../../../src/pages/community-investment/support-application/index.tsx" /* webpackChunkName: "component---src-pages-community-investment-support-application-index-tsx" */),
  "component---src-pages-community-investment-volunteerism-application-index-tsx": () => import("./../../../src/pages/community-investment/volunteerism-application/index.tsx" /* webpackChunkName: "component---src-pages-community-investment-volunteerism-application-index-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-contract-manufacturing-confirmation-index-tsx": () => import("./../../../src/pages/contract-manufacturing/confirmation/index.tsx" /* webpackChunkName: "component---src-pages-contract-manufacturing-confirmation-index-tsx" */),
  "component---src-pages-contract-manufacturing-index-tsx": () => import("./../../../src/pages/contract-manufacturing/index.tsx" /* webpackChunkName: "component---src-pages-contract-manufacturing-index-tsx" */),
  "component---src-pages-ctsc-disclosure-index-tsx": () => import("./../../../src/pages/ctsc-disclosure/index.tsx" /* webpackChunkName: "component---src-pages-ctsc-disclosure-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-articles-html-tsx": () => import("./../../../src/pages/news/articles.html.tsx" /* webpackChunkName: "component---src-pages-news-articles-html-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-news-mdx-fields-year-index-tsx-content-file-path-src-data-press-releases-2022-01-28-genesee-ny-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2022-01-28-genesee-ny/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-index-tsx-content-file-path-src-data-press-releases-2022-01-28-genesee-ny-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-index-tsx-content-file-path-src-data-press-releases-2023-12-01-labatt-sabers-retro-cans-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2023-12-01-labatt-sabers-retro-cans/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-index-tsx-content-file-path-src-data-press-releases-2023-12-01-labatt-sabers-retro-cans-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-index-tsx-content-file-path-src-data-press-releases-2024-04-09-lipton-hard-iced-tea-rolls-out-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2024-04-09-lipton-hard-iced-tea-rolls-out/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-index-tsx-content-file-path-src-data-press-releases-2024-04-09-lipton-hard-iced-tea-rolls-out-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-index-tsx-content-file-path-src-data-press-releases-2022-01-28-genesee-ny-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2022-01-28-genesee-ny/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-index-tsx-content-file-path-src-data-press-releases-2022-01-28-genesee-ny-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-index-tsx-content-file-path-src-data-press-releases-2022-07-11-seagrams-cleanup-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2022-07-11-seagrams-cleanup/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-index-tsx-content-file-path-src-data-press-releases-2022-07-11-seagrams-cleanup-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-index-tsx-content-file-path-src-data-press-releases-2022-10-04-genesee-brewers-release-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2022-10-04-genesee-brewers-release/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-index-tsx-content-file-path-src-data-press-releases-2022-10-04-genesee-brewers-release-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-index-tsx-content-file-path-src-data-press-releases-2022-11-01-labatt-usa-signs-five-year-agreement-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2022-11-01-labatt-usa-signs-five-year-agreement/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-index-tsx-content-file-path-src-data-press-releases-2022-11-01-labatt-usa-signs-five-year-agreement-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-index-tsx-content-file-path-src-data-press-releases-2022-12-06-keg-tree-lit-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2022-12-06-keg-tree-lit/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-index-tsx-content-file-path-src-data-press-releases-2022-12-06-keg-tree-lit-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-index-tsx-content-file-path-src-data-press-releases-2023-12-01-labatt-sabers-retro-cans-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2023-12-01-labatt-sabers-retro-cans/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-index-tsx-content-file-path-src-data-press-releases-2023-12-01-labatt-sabers-retro-cans-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-index-tsx-content-file-path-src-data-press-releases-2024-04-09-lipton-hard-iced-tea-rolls-out-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2024-04-09-lipton-hard-iced-tea-rolls-out/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-index-tsx-content-file-path-src-data-press-releases-2024-04-09-lipton-hard-iced-tea-rolls-out-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-index-tsx-content-file-path-src-data-press-releases-2024-05-30-blue-light-rasp-lemon-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2024-05-30-blue-light-rasp-lemon/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-index-tsx-content-file-path-src-data-press-releases-2024-05-30-blue-light-rasp-lemon-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-index-tsx-content-file-path-src-data-press-releases-2024-07-29-labatt-zubaz-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2024-07-29-labatt-zubaz/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-index-tsx-content-file-path-src-data-press-releases-2024-07-29-labatt-zubaz-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-index-tsx-content-file-path-src-data-press-releases-2024-08-01-genesee-bcvk-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2024-08-01-genesee-bcvk/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-index-tsx-content-file-path-src-data-press-releases-2024-08-01-genesee-bcvk-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-index-tsx-content-file-path-src-data-press-releases-2024-09-04-labatt-syracuse-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2024-09-04-labatt-syracuse/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-index-tsx-content-file-path-src-data-press-releases-2024-09-04-labatt-syracuse-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2022-01-28-genesee-ny-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2022-01-28-genesee-ny/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2022-01-28-genesee-ny-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2022-07-11-seagrams-cleanup-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2022-07-11-seagrams-cleanup/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2022-07-11-seagrams-cleanup-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2022-10-04-genesee-brewers-release-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2022-10-04-genesee-brewers-release/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2022-10-04-genesee-brewers-release-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2022-10-12-its-finally-here-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2022-10-12-its-finally-here/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2022-10-12-its-finally-here-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2022-11-01-labatt-usa-signs-five-year-agreement-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2022-11-01-labatt-usa-signs-five-year-agreement/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2022-11-01-labatt-usa-signs-five-year-agreement-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2022-11-28-labatt-blue-flyers-promo-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2022-11-28-labatt-blue-flyers-promo/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2022-11-28-labatt-blue-flyers-promo-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2022-12-06-keg-tree-lit-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2022-12-06-keg-tree-lit/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2022-12-06-keg-tree-lit-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2023-12-01-labatt-sabers-retro-cans-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2023-12-01-labatt-sabers-retro-cans/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2023-12-01-labatt-sabers-retro-cans-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2024-04-09-lipton-hard-iced-tea-rolls-out-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2024-04-09-lipton-hard-iced-tea-rolls-out/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2024-04-09-lipton-hard-iced-tea-rolls-out-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2024-04-11-labatt-parks-and-rec-returns-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2024-04-11-labatt-parks-and-rec-returns/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2024-04-11-labatt-parks-and-rec-returns-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2024-04-28-mango-peach-kolsh-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2024-04-28-mango-peach-kolsh/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2024-04-28-mango-peach-kolsh-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2024-05-30-blue-light-rasp-lemon-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2024-05-30-blue-light-rasp-lemon/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2024-05-30-blue-light-rasp-lemon-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2024-07-29-labatt-zubaz-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2024-07-29-labatt-zubaz/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2024-07-29-labatt-zubaz-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2024-08-01-genesee-bcvk-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2024-08-01-genesee-bcvk/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2024-08-01-genesee-bcvk-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2024-09-04-labatt-syracuse-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2024-09-04-labatt-syracuse/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2024-09-04-labatt-syracuse-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2024-09-23-sgrm-tv-drinks-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/index.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2024-09-23-sgrm-tv-drinks/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-index-tsx-content-file-path-src-data-press-releases-2024-09-23-sgrm-tv-drinks-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2022-01-28-genesee-ny-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/{mdx.frontmatter__slug}.html.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2022-01-28-genesee-ny/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2022-01-28-genesee-ny-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2022-01-28-labatt-supports-buffalo-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/{mdx.frontmatter__slug}.html.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2022-01-28-labatt-supports-buffalo/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2022-01-28-labatt-supports-buffalo-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2022-07-11-seagrams-cleanup-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/{mdx.frontmatter__slug}.html.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2022-07-11-seagrams-cleanup/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2022-07-11-seagrams-cleanup-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2022-10-04-genesee-brewers-release-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/{mdx.frontmatter__slug}.html.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2022-10-04-genesee-brewers-release/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2022-10-04-genesee-brewers-release-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2022-10-12-its-finally-here-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/{mdx.frontmatter__slug}.html.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2022-10-12-its-finally-here/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2022-10-12-its-finally-here-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2022-11-01-labatt-usa-signs-five-year-agreement-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/{mdx.frontmatter__slug}.html.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2022-11-01-labatt-usa-signs-five-year-agreement/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2022-11-01-labatt-usa-signs-five-year-agreement-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2022-11-28-labatt-blue-flyers-promo-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/{mdx.frontmatter__slug}.html.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2022-11-28-labatt-blue-flyers-promo/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2022-11-28-labatt-blue-flyers-promo-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2022-11-28-labatt-blue-penguins-promo-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/{mdx.frontmatter__slug}.html.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2022-11-28-labatt-blue-penguins-promo/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2022-11-28-labatt-blue-penguins-promo-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2022-12-06-keg-tree-lit-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/{mdx.frontmatter__slug}.html.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2022-12-06-keg-tree-lit/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2022-12-06-keg-tree-lit-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2022-12-06-rockefeller-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/{mdx.frontmatter__slug}.html.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2022-12-06-rockefeller/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2022-12-06-rockefeller-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2023-12-01-labatt-sabers-retro-cans-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/{mdx.frontmatter__slug}.html.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2023-12-01-labatt-sabers-retro-cans/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2023-12-01-labatt-sabers-retro-cans-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2024-04-09-lipton-hard-iced-tea-rolls-out-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/{mdx.frontmatter__slug}.html.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2024-04-09-lipton-hard-iced-tea-rolls-out/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2024-04-09-lipton-hard-iced-tea-rolls-out-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2024-04-09-seagrams-escapes-refreshers-party-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/{mdx.frontmatter__slug}.html.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2024-04-09-seagrams-escapes-refreshers-party/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2024-04-09-seagrams-escapes-refreshers-party-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2024-04-11-labatt-parks-and-rec-returns-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/{mdx.frontmatter__slug}.html.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2024-04-11-labatt-parks-and-rec-returns/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2024-04-11-labatt-parks-and-rec-returns-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2024-04-28-mango-peach-kolsh-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/{mdx.frontmatter__slug}.html.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2024-04-28-mango-peach-kolsh/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2024-04-28-mango-peach-kolsh-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2024-05-30-blue-light-rasp-lemon-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/{mdx.frontmatter__slug}.html.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2024-05-30-blue-light-rasp-lemon/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2024-05-30-blue-light-rasp-lemon-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2024-07-29-labatt-zubaz-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/{mdx.frontmatter__slug}.html.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2024-07-29-labatt-zubaz/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2024-07-29-labatt-zubaz-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2024-08-01-genesee-bcvk-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/{mdx.frontmatter__slug}.html.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2024-08-01-genesee-bcvk/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2024-08-01-genesee-bcvk-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2024-09-04-labatt-syracuse-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/{mdx.frontmatter__slug}.html.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2024-09-04-labatt-syracuse/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2024-09-04-labatt-syracuse-index-mdx" */),
  "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2024-09-23-sgrm-tv-drinks-index-mdx": () => import("./../../../src/pages/news/{mdx.fields__year}/{mdx.fields__month}/{mdx.fields__day}/{mdx.frontmatter__slug}.html.tsx?__contentFilePath=/opt/build/repo/src/data/press_releases/2024-09-23-sgrm-tv-drinks/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-fields-year-mdx-fields-month-mdx-fields-day-mdx-frontmatter-slug-html-tsx-content-file-path-src-data-press-releases-2024-09-23-sgrm-tv-drinks-index-mdx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-smart-consumption-index-tsx": () => import("./../../../src/pages/smart-consumption/index.tsx" /* webpackChunkName: "component---src-pages-smart-consumption-index-tsx" */),
  "component---src-pages-terms-index-tsx": () => import("./../../../src/pages/terms/index.tsx" /* webpackChunkName: "component---src-pages-terms-index-tsx" */)
}

