export const enum AgeGateCookieEnum {
    CookieName = "ageCheck",
    ConsentAllValue = "a",
    ConsentReqValue = "r",
    CookieDuration = 3600 * 24 * 30, // 30 days, in seconds
}

export const enum ThemeModeEnum {
    auto = "auto",
    dark = "dark",
    light = "light",
}

export const enum ContractManufacturingAnchorsEnum {
    ANCHOR = "form_anchor",
    FORM = "mj140t50kcaosk", // Pulled from Wufoo form script.  Don't change this unless changing the form.
    PKG_DETAIL = "pkg_detail",
    VIDEO = "video_el",
}
